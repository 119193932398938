:host {
	display: block;
}

/*
  Had to restyle the multiselect menu since it uses padding and margins outside the clickable areas,
  making it easy to accidentally close the menu instead of selecting an item.
*/

.phd-flexbox {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	min-width: 100%;
	border-right: 1px solid #fff;
	border-bottom-right-radius: 3px;
	border-top-right-radius: 3px;
	margin: 0;
}

.p-component {
	font-size: 14px;
}

p-multiselect {

	.p-multiselect {
		min-width: 15em;
		max-width: 15em;

		.p-multiselect-header {
			padding: .25em;
			background: linear-gradient(to bottom, #f6f7f9 0%, #ebedf0 100%);
			border: 1px solid #d9d9d9;
			border-radius: 3px;

			.p-multiselect-close {
				width: 1em;
				height: 1em;
			}
		}

		.p-multiselect-panel {
			padding: .2em;
		}

		&-label-container {
			display: block;
			margin: 0;
			padding: 0;
			width: 100%;
		}

		.p-multiselect-label {
			display: block;
			width: auto;
			padding: .3em 1em;
			margin: 0;
		}

		&-trigger {
			display: flex;
			align-items: center;
			padding: 0;
		}

		.p-multiselect-trigger {
			border-radius: 7px;
		}

		.p-chkbox-box {
			width: 1.125em;
			height: 1.125em;
			line-height: 1.125em;
			border-radius: 2px;
			text-align: center;

			.p-chkbox-icon {
				font-size: 14px;
			}
		}

		&-panel .p-multiselect-items-wrapper .p-multiselect-items {
			.p-multiselect-item {
				padding: 0;

				&[data-p-highlight="true"] {
					color: #fff;
					background: #007ad9;
				}

				.p-chkbox-icon, label {
					background: #eeeeee;
					border-bottom: 1px solid #fff;
					border-top: 1px solid #fff;
					cursor: pointer;
				}

				label {
					border-right: 1px solid #fff;
					border-bottom-right-radius: 3px;
					border-top-right-radius: 3px;
					display: block;
					flex: 1 1 auto;
					line-height: 16px;
					margin: 0;
					padding: 6px 8px;
				}

				.p-checkbox {
					display: none;
				}
			}
		}
	}
}

/* Modal styling specific to the search modal */
.phd-search-modal .modal-dialog {
	min-width: 30%;
	max-width: 90%;
}

.phd-search-container {
	sales-community-selector {
		background: #fff;
		border-bottom: none;
	}
	/*WHEN SEARCH INPUTS ARE VISIBLE*/
	.phd-search-options {
		flex-shrink: 1;
		font-size: 14px !important;
		transition: flex 400ms ease-out;
		display: flex;
		width: 100%;
		justify-content: space-between;
		padding: 1.5em .5em;
		background-color: #fff;

		section {
			display: flex;
			flex-direction: row;
			width: 100%;
			flex: 1;
			padding: 0 1em;
			white-space: nowrap;

			> div {
				flex: 1;
			}

			&:first-child {
				padding-left: 0;
			}

			&.phd-buttons-holder button {
				font-size: 12px;
				margin-bottom: 4px;
				padding: 4px;
				white-space: normal;
				width: 12em;
			}

			label {
				font-weight: bold !important;
			}

			input {
				border: 1px solid #ccc;
				border-radius: 7px;
				display: block;
				margin-bottom: .5em;
				padding: 3px;
				width: 60%;
				min-width: 15em;
			}
		}
	}
	/*WHEN SEARCH INPUTS ARE HIDDEN*/
	&.phd-search-closed {
		.phd-search-options {
			display: flex !important;
			flex-direction: row;
			justify-content: flex-start !important;
			margin-right: 4px;

			section {
				display: flex;
				margin-bottom: 1em;
				min-width: unset;
				flex: 0;
			}

			.phd-badge {
				cursor: pointer;
				display: flex;
				flex: 1 1 auto;
				flex-direction: row;
				margin: 0 3px;
				width: auto;

				&:first-child {
					margin-left: 0;
				}

				&.phd-back {
					strong, span {
						background: #eee;
						box-shadow: -1px 1px 0px #aaa;
						color: #999;
					}
				}

				strong, span {
					background: #0d4b6b;
					color: #fff;
					display: inline-block;
					padding: 4px 8px;
				}

				&:hover {
					strong, span {
						background: #007bff;
						box-shadow: -1px 1px 0px #0d4b6b;
						color: #fff;
					}
				}

				span {
					border-radius: .25rem;
					border-right: 1px solid #fff;
				}

				strong {
					border-bottom-right-radius: .25rem;
					border-top-right-radius: .25rem;
				}
			}
		}
	}
}

.phd-search-button-holder {
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
}

.phd-filter {
	flex: 1;
	text-align: center;
}

.phd-search {
	flex: 1;
}

.phd-search-results {
	&-holder {
		border-radius: 2px;
		max-height: .1px;
		min-height: .1px;
		overflow-y: hidden;
		transition: min-height 400ms, max-height 400ms ease-out;
	}

	&-result {

		&.no-results {
			display: block;
		}

		&.has-results {
			display: block;
		}
	}

	p-table, table, phd-table {
		width: 100%;
	}

	phd-table {
		display: block;
		overflow-y: auto;
	}

	tr.p-highlight {
		a {
			color: #fff;
		}
	}
}

.phd-results-opened {

	.phd-search-results {
		border-bottom-left-radius: 10px;
		border-bottom-right-radius: 10px;
		padding-bottom: 1.5rem;

		&-holder {
			background: linear-gradient(0deg, #e7e7e7 0%, white 100%);
			border: 1px solid #ccc;
			box-shadow: 2px 2px 5px #ccc;
			max-height: 500px;
			min-height: 100px;
		}
	}

	.phd-search-button-holder {
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
	}
}

span.phd-badge.phd-back {
	color: #0d4b6b;
	height: 100%;

	&:hover {
		color: #007bff;
	}

	svg {
		height: 100%;
	}
}

.phd-panel-style {
	font-size: 12px;
	color: pink;
}

.phd-active-change-order {
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
}
