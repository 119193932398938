/* Modal styling specific to the preview modal -> portal-item.component.ts */
.phd-binoculars-modal {
	.modal-body {
		padding: .5em;
	}

	sales-community-selector {
		border-bottom: none;
		padding: 0;
	}

	.phd-plan-select {
		background: #fff !important;

		.form-group {
			display: inline-block;
			margin-bottom: 0;
			width: 100%;
			padding: .25rem .55rem;

			select {
				display: inline-block;
				font-size: 13px;
				text-indent: 0;
				width: 100% !important;

				&:disabled {
					background: none;
					color: #888;
				}

				option {
					border: none;
					outline: none;
					text-indent: 0;
				}
			}

			select:focus {
				background: none;
			}
		}
	}

	.phd-button-group {
		background: #fff;
		margin: 0;
		padding: 1em;
	}

	sales-community-selector {
		background: #fff !important;

		form > div {
			flex-direction: column !important;
		}

		.phd-select-menus .form-group {
			margin: 0;
			padding: .25em;
			width: 100%;
		}
	}
}
