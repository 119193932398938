/* Modal styling specific to the spec homes modal -> portal-item.component.ts */
.phd-home-modal {
	.modal-body {
		padding: .5em;
	}

	.phd-build-type-group {
		display: flex;
		justify-content: space-evenly;
		margin: 10px 0;
		width: 100%;

		button {
			width: 200px;
		}
	}

	.phd-button-group {
		background: #fff;
		margin: 0;
		padding: 1em;
	}

	sales-community-selector {
		background: #fff;
		border-bottom: none;
		padding: 0;

		form > div {
			flex-direction: column !important;
		}

		.phd-select-menus .form-group {
			margin: 0;
			padding: .25em;
			width: 100%;
		}
	}
}
