sales-community-selector {
  background: rgb(239, 239, 239);
  border-bottom: 1px solid #ccc;
  display: block;
  padding: .25rem .55rem;

  .phd-select-menus {
    .form-group {
      display: inline-block;
      margin-bottom: 0;
      width: 32%;

      select {
        display: inline-block;
        font-size: 13px;
        text-indent: 0;
        width: 100% !important;

        &:disabled {
          background: none;
          color: #888;
        }

        option {
          border: none;
          outline: none;
          text-indent: 0;
        }
      }

      select:focus {
        background: none;
      }
    }
  }
}
