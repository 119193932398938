.phd-portal-container {
	background: rgba(162,162,162,.7);
	border-radius: 15px;
	color: #999;
	cursor: not-allowed;
	display: flex;
	flex-basis: 100%;
	flex-direction: column;
	margin: 15px;
	padding: 10px;
	text-align: center;

	&.phd-hasLink {
		color: #fff;
		cursor: pointer;
		transition: background-color 300ms;

		&:hover {
			background: rgba(162,162,162,1);
			text-decoration: none;
		}
	}

	h3 {
		font-size: 16pt;
		margin: 0;
		padding: 0;
	}

	.phd-icon {
		align-items: center;
		display: flex;
		flex: 1 1 100%;
		font-size: 90px;
		justify-content: center;

		svg {
			max-height: 80%;
			max-width: 80%;
			min-height: 50%;
			min-width: 50%;
		}
	}
}

.phd-modal-window {
	cursor: not-allowed;
	.modal-dialog {
		.modal-content {
			cursor: default;
		}
	}
}

#phdClickContent {
	display: none;
}

@media only screen and (max-width: 530px) {
	.phd-portal-container h3 {
		font-size: 18px;
	}
}

@media only screen and (max-width: 617px) and (max-height: 517px) {
	.phd-portal-container {
		margin: 10px;

		h3 {
			font-size: 14px;
		}
	}
}

@media only screen and (max-width: 417px) {
	.phd-portal-container {
		margin: 5px;

		h3 {
			font-size: 12px;
		}
	}
}
